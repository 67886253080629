var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "默认设置",
        visible: _setup.props.show,
        width: "700px",
        "before-close": _setup.clearDialog,
        "show-close": !_setup.props.isAudit,
        "close-on-click-modal": !_setup.props.isAudit,
        "close-on-press-escape": true,
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_setup.props, "show", $event)
        },
        open: _setup.open,
      },
    },
    [
      _c(
        "div",
        [
          _c("div", { staticClass: "fw700 f20 margin-b10" }, [
            _vm._v(" 每日规格 "),
          ]),
          _c(
            "el-card",
            [
              _c(_setup.SkuSpecification, {
                attrs: {
                  "goods-id": +_setup.props.goodsId,
                  "spec-list": _setup.specList,
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "red margin-t10 annotation" }, [
            _vm._v(
              " 注：每日规格被修改，各门店每日规格将同步被修改，包括您特别设置过的日期规格，且每日状态将重置为启售，请谨慎操作。(价格与库存日历中，特别设置过的日期的库存显示红色。) "
            ),
          ]),
          _c("el-divider"),
          _c("div", { staticClass: "fw700 f20 margin-b10" }, [
            _vm._v(" 默认价格与库存 "),
          ]),
          _c(
            "el-card",
            [
              _c(
                "el-form",
                {
                  ref: "formRef",
                  attrs: { model: _setup.ruleForm, "label-width": "140px" },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "默认销售价(元)",
                        rules: [
                          {
                            required: true,
                            message: "请输入默认销售价",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input-number", {
                        staticClass: "input-w300",
                        attrs: {
                          size: "small",
                          controls: false,
                          precision: 2,
                          min: 0,
                          placeholder: "请输入销售价",
                        },
                        model: {
                          value: _setup.ruleForm.price,
                          callback: function ($$v) {
                            _vm.$set(_setup.ruleForm, "price", $$v)
                          },
                          expression: "ruleForm.price",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "默认库存",
                        rules: [
                          {
                            required: true,
                            message: "请输入默认库存",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input-number", {
                        staticClass: "input-w300",
                        attrs: {
                          size: "small",
                          controls: false,
                          precision: 0,
                          min: 0,
                          placeholder: "请输入库存",
                        },
                        model: {
                          value: _setup.ruleForm.num,
                          callback: function ($$v) {
                            _vm.$set(_setup.ruleForm, "num", $$v)
                          },
                          expression: "ruleForm.num",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "red margin-t10 annotation" }, [
            _vm._v(
              " 注：默认销售价、库存被修改，各门店日期规格使用的默认销售价、库存将同步被修改。您特别设置过日期的销售价、库存，将不受影响。(价格与库存日历中，特别设置过的日期的库存显示红色。) "
            ),
          ]),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _setup.onSubmit },
            },
            [_vm._v(_vm._s(_setup.props.isAudit ? "提交审核" : "保 存") + " ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }