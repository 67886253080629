var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-dialog",
    {
      attrs: {
        title: `选择门店${_setup.props.isSingle ? "(单选)" : "(多选)"}`,
        visible: _setup.props.show,
        width: "700px",
        "before-close": _setup.clearDialog,
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_setup.props, "show", $event)
        },
        open: _setup.onOpen,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _setup.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "el-checkbox-group",
            {
              attrs: { max: _vm.isSingle ? 1 : undefined },
              model: {
                value: _setup.selectStoreList,
                callback: function ($$v) {
                  _setup.selectStoreList = $$v
                },
                expression: "selectStoreList",
              },
            },
            _vm._l(_setup.storeList, function (item, index) {
              return _c(
                "el-checkbox",
                {
                  key: index,
                  staticClass: "my_checkbox",
                  attrs: { label: item },
                },
                [
                  _c("div", { staticClass: "my_title" }, [
                    _vm._v(" 门店名称：" + _vm._s(item.name) + " "),
                  ]),
                  _c("div", { staticClass: "my_title" }, [
                    _vm._v(" 联系方式：" + _vm._s(item.moblie) + " "),
                  ]),
                  _c("div", { staticClass: "my_title" }, [
                    _vm._v(" 门店地址：" + _vm._s(item.address) + " "),
                  ]),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.hasAddShop
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _setup.toAddShop },
                },
                [_vm._v("新增门店")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _setup.onSubmit },
            },
            [_vm._v(_vm._s(_vm.buttonText))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }