var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-dialog",
    {
      attrs: {
        title: `${_setup.props.day}规格`,
        visible: _setup.props.show,
        width: "700px",
        "before-close": _setup.clearDialog,
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_setup.props, "show", $event)
        },
        open: _setup.open,
      },
    },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _setup.tableData, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "specValue", label: "规格" },
          }),
          _c(
            "el-table-column",
            {
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("el-input-number", {
                        staticStyle: { width: "95%" },
                        attrs: {
                          min: 0.01,
                          max: 9999999,
                          placeholder: "请输入销售价（元）",
                          size: "small",
                          controls: false,
                          precision: 2,
                        },
                        model: {
                          value: row.sellPrice,
                          callback: function ($$v) {
                            _vm.$set(row, "sellPrice", $$v)
                          },
                          expression: "row.sellPrice",
                        },
                      }),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("span", { staticClass: "red" }, [_vm._v("*")]),
                  _vm._v(" 销售价（元） "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content:
                          "销售价为商品正常销售时的价格，可以编辑修改，修改后该商品将重新进行审核",
                        placement: "top",
                      },
                    },
                    [
                      _c("el-button", { attrs: { type: "text" } }, [
                        _c("i", { staticClass: "el-icon-warning-outline" }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-table-column",
            {
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("el-input-number", {
                        staticStyle: { width: "95%" },
                        attrs: {
                          min: 0,
                          max: 9999999,
                          placeholder: "请输入库存",
                          size: "small",
                          controls: false,
                          precision: 0,
                        },
                        model: {
                          value: row.stockQuantity,
                          callback: function ($$v) {
                            _vm.$set(row, "stockQuantity", $$v)
                          },
                          expression: "row.stockQuantity",
                        },
                      }),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("span", { staticClass: "red" }, [_vm._v("*")]),
                  _vm._v(" 库存（件） "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content:
                          "该库存表示此规格在系统中实时的可售卖库存数量，可以直接编辑修改，修改保存成功，系统中的库存数量会更新为最新库存值",
                        placement: "top",
                      },
                    },
                    [
                      _c("el-button", { attrs: { type: "text" } }, [
                        _c("i", { staticClass: "el-icon-warning-outline" }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _setup.props.type !== "show"
        ? _c("div", { staticClass: "red margin-t10" }, [
            _vm._v(
              " 注：点击确定并设置成功后，以上被您特别设置过的日期，在默认销售价、库存被修改时不受影响，不会跟着被修改。(价格与库存日历中，特别设置过的日期的库存显示红色。) "
            ),
          ])
        : _vm._e(),
      _setup.props.type !== "show"
        ? _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _setup.clearDialog },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _setup.onSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }