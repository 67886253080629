<script setup>
import {
  ref, reactive, computed, onMounted, getCurrentInstance,
} from 'vue';
import OfflineStoreDialog from '@/components/common/OfflineStoreDialog/index.vue';
import DayCalendar from '@/components/common/DayCalendar/index.vue';
import SpecDialog from './SpecDialog.vue';
import BatchSettingDialog from './BatchSettingDialog.vue';
import DaySkuDialog from './DaySkuDialog.vue';

const app = getCurrentInstance().proxy;

const { query } = app.$route;

const props = defineProps({
  type: {
    type: String,
    default: '',
  },
});

const showOfflineStore = ref(false);
const selectOfflineStore = ref([]);
const showSpecDialog = ref(false);
const pickCalendarsObj = ref({});
const defaultPickCalendars = ref({});
const shopList = ref([]);
const activeShopId = ref('');
const isAudit = ref(false); // 是否首次审核；
const showBathSetting = ref(false); // 批量设置弹窗；
const bathSettingType = ref('price'); // 批量设置状态
const bathSetShopList = ref([]); // 批量设置店铺

const showDaySku = ref(false); // 设置单日sku
const selectDay = ref('');

// function
const formatDayCalendar = (pick) => {
  let obj = {};
  pick.forEach((item) => {
    if (item.dateOne) {
      obj[item.dateOne] = item;
    }
  });
  // 每日日历库存信息
  pickCalendarsObj.value = obj;
  // 默认日历库存信息
  defaultPickCalendars.value = pick.find((item) => +item.isDefault === 1);
};
const getDefaultSpec = async (message) => {
  const res = await app.$axios.get(app.$api.goods_pick.getGoodPickSku, {
    params: {
      goodsId: query.goodsId,
      shopIds: activeShopId.value,
    },
  });
  // if (res.code !== 0) return;
  // 是否是首次设置默认
  if (!res.data || !res.data.sellPrice || !res.data.stockQuantity) {
    isAudit.value = true;
    showSpecDialog.value = true;
    return;
  }
  // 设置日历每日信息
  formatDayCalendar(res.data.pickCalendars);
  // 提示
  if (message) app.$message.success(message);
};

const changeShop = (e) => {
  console.log(e);
  activeShopId.value = e;
  getDefaultSpec('门店切换成功！');
};

const onBathSetting = (type) => {
  bathSettingType.value = type;
  // 选择店铺
  showOfflineStore.value = true;
};
const onBack = () => {
  showBathSetting.value = false;
  showOfflineStore.value = true;
};
const onChangeOfflineStore = (e) => {
  selectOfflineStore.value = e;
  bathSetShopList.value = e.map((item) => item.id);
  // 批量设置价格
  showBathSetting.value = true;
};
// 设置单日状态
const setOneState = (type, day) => {
  app.$confirm('是否确认修改?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(async () => {
    // 修改状态
    let api = app.$api.goods_pick.batchOpera;
    let params = {
      goodsId: query.goodsId,
      dateOneList: [day],
      shopIds: [activeShopId.value],
      operaType: type === 'stop' ? 0 : 1,
    };
    const res = await app.$axios.post(api, params);
    if (res.code !== 0) return;
    app.$message.success('操作成功！');
    getDefaultSpec();
  }).catch(() => {
  });
};
// 设置单日价格
const setOneDay = (item) => {
  selectDay.value = item;
  showDaySku.value = true;
};
// 默认规格修改成功
const onSpecSuccess = () => {
  // 提交审核
  if (isAudit.value) {
    app.$router.replace('/nb/goods/index');
  } else {
    getDefaultSpec();
  }
};

onMounted(async () => {
  const res = await app.$axios.get(app.$api.goods_pick.getShopInfoByGoodId, {
    params: { goodsId: query.goodsId },
  });
  if (res.code !== 0) return;
  if (!res.data || !res.data.length) {
    app.$message.error('暂无门店');
    return;
  }
  shopList.value = res.data;
  activeShopId.value = res.data[0].id;
  getDefaultSpec();
});
</script>

<template>
  <div class="app-container">
    <div
      v-if="props.type !== 'show'"
      class="margin-b10"
    >
      <el-button
        type="primary"
        size="small"
        @click="showSpecDialog = true"
      >
        默认设置
      </el-button>
      <el-button
        size="small"
        @click="onBathSetting('price')"
      >
        批量改价格与库存
      </el-button>
      <el-button
        size="small"
        @click="onBathSetting('state')"
      >
        批量启售/停售
      </el-button>
    </div>

    <div
      class="shop_content"
    >
      切换门店：
      <el-select
        v-model="activeShopId"
        size="small"
        @change="changeShop"
      >
        <el-option
          v-for="item in shopList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
    </div>

    <!-- 日历 -->
    <DayCalendar @click="setOneDay">
      <template #default="{day}">
        <div class="margin-t5 margin-b5">
          库存：
          <span
            v-if="pickCalendarsObj[day]"
            class="red"
          >
            {{ pickCalendarsObj[day].stockQuantity }}</span>
          <span v-else>{{ defaultPickCalendars.stockQuantity }}</span>
        </div>
        <div class="margin-b5">
          状态：
          <span
            v-if="pickCalendarsObj[day]"
            :class="{'red': +pickCalendarsObj[day].status !== 1}"
          >
            {{ +pickCalendarsObj[day].status === 1 ? '启售' : '停售' }}</span>
          <span v-else>{{ +defaultPickCalendars.status === 1 ? '启售' : '停售' }}</span>
        </div>
        <template v-if="props.type !== 'show'">
          <el-button
            v-if="(pickCalendarsObj[day] && +pickCalendarsObj[day].status === 1)
              || (!pickCalendarsObj[day] && +defaultPickCalendars.status === 1)"
            plain
            size="mini"
            @click.stop="setOneState('stop', day)"
          >
            停售
          </el-button>
          <el-button
            v-else
            type="primary"
            plain
            size="mini"
            @click.stop="setOneState('start', day)"
          >
            启售
          </el-button>
        </template>
      </template>
    </DayCalendar>
    <!-- 设置默认规则 -->
    <SpecDialog
      :is-audit="isAudit"
      :show="showSpecDialog"
      :goods-id="+query.goodsId"
      :shop-list="shopList"
      :backend-category-id="+query.backendCategoryId"
      @clear="showSpecDialog = false"
      @success="onSpecSuccess"
    />
    <!-- 选择门店 -->
    <OfflineStoreDialog
      :is-single="false"
      :show="showOfflineStore"
      :goods-id="+query.goodsId"
      :list="selectOfflineStore"
      button-text="下一步"
      @clear="showOfflineStore = false"
      @change="onChangeOfflineStore"
    />
    <!-- 批量设置价格 -->
    <BatchSettingDialog
      :show="showBathSetting"
      :set-type="bathSettingType"
      :goods-id="+query.goodsId"
      :shop-list="bathSetShopList"
      @clear="showBathSetting = false"
      @success="getDefaultSpec();"
      @back="onBack"
    />
    <!-- 设置单日sku价格库存 -->
    <DaySkuDialog
      :show="showDaySku"
      :day="selectDay"
      :goods-id="+query.goodsId"
      :shop-id="+activeShopId"
      :type="props.type"
      @clear="showDaySku = false"
      @success="getDefaultSpec();"
    />
  </div>
</template>

<style scoped lang="scss">
.shop_content {
  background: #fff;
  padding: 10px;
}
</style>
