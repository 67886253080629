// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.47.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.nb-day-calendar .el-calendar__title {\n  font-size: 18px;\n  font-weight: bold;\n}\n.nb-day-calendar .el-calendar-table .el-calendar-day {\n  height: auto;\n}", ""]);
// Exports
exports.locals = {
	"theme": "#30B08F",
	"menuBg": "#304156",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
