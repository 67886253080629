<script setup>
import { ref, reactive, getCurrentInstance } from 'vue';

const app = getCurrentInstance().proxy;

const props = defineProps({
  setType: {
    type: String,
    default: '',
    required: true,
  },
  show: {
    type: Boolean,
    default: false,
    required: true,
  },
  goodsId: {
    type: Number,
    default: null,
    required: true,
  },
  shopList: {
    type: Array,
    default: () => [],
    required: true,
  },
});
const emit = defineEmits('clear', 'success', 'back');

const rules = {
  homeState: [{ required: true }],
  dayTimeType: [{ required: true }],
  checkDays: [
    {
      type: 'array', required: true, message: '请选择日期', trigger: 'change',
    },
  ],
  sellPrice: [
    { required: true, message: '请选输入价格', trigger: 'blur' },
  ],
  stockQuantity: [
    { required: true, message: '请输入库存', trigger: 'blur' },
  ],
};

const ruleForm = ref(null);
const form = reactive({
  homeState: 0,
  dayTimeType: 1,
  checkDays: [],
  sellPrice: undefined,
  stockQuantity: undefined,
});

function formatTitle() {
  return props.setType === 'state' ? '状态设置' : '价格与库存设置';
}
function clearDialog() {
  emit('clear');
}
function getTwoDaybetween(arr) {
  let startDate = new Date(arr[0]);
  let endDate = new Date(arr[1]);
  let newDay = [];
  while (endDate.getTime() >= startDate.getTime()) {
    let year = startDate.getFullYear();
    let month = startDate.getMonth() + 1;
    let day = startDate.getDate();
    // 加一天
    startDate.setDate(day + 1);
    if (month.toString().length === 1) {
      month = `0${month}`;
    }
    if (day.toString().length === 1) {
      day = `0${day}`;
    }
    newDay.push(`${year}-${month}-${day}`);
  }
  return newDay;
}
function onSubmit() {
  ruleForm.value.validate((valid) => {
    if (!valid) return;
    const { dayTimeType, checkDays } = form;
    let newArray = dayTimeType === 1 ? getTwoDaybetween(checkDays) : checkDays;
    if (newArray.length > 31) {
      app.$message.error(`单次设置最多可选31天（已选${newArray.length}天）`);
      return;
    }
    app.$confirm('是否确认提交?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(async () => {
      let api;
      let params;
      const {
        homeState, sellPrice, stockQuantity,
      } = form;
      if (props.setType === 'price') {
        // 修改价格库存
        api = app.$api.goods_pick.batchModifyPriceAndStock;
        params = {
          goodsId: props.goodsId,
          sellPrice,
          stockQuantity,
          dateOneList: newArray,
          shopIds: props.shopList,
        };
      } else if (props.setType === 'state') {
        // 修改状态
        api = app.$api.goods_pick.batchOpera;
        params = {
          goodsId: props.goodsId,
          dateOneList: newArray,
          shopIds: props.shopList,
          operaType: homeState,
        };
      }
      console.log(params);
      const res = await app.$axios.post(api, params);
      if (res.code !== 0) return;
      app.$message.success('操作成功！');
      emit('success');
      emit('clear');
    }).catch(() => {
    });
  });
}
function onBack() {
  emit('back');
}
</script>

<template>
  <el-dialog
    :title="formatTitle()"
    :visible.sync="show"
    width="550px"
    :before-close="clearDialog"
  >
    <el-form
      ref="ruleForm"
      :model="form"
      :rules="rules"
      label-position="right"
      label-width="110px"
    >
      <el-form-item
        v-if="props.setType === 'state'"
        label="状态设置:"
        prop="homeState"
      >
        <el-radio-group v-model="form.homeState">
          <el-radio :label="0">
            停售
          </el-radio>
          <el-radio :label="1">
            启售
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="选择方式:"
        prop="dayTimeType"
      >
        <el-radio-group
          v-model="form.dayTimeType"
        >
          <el-radio :label="1">
            按起止日期
          </el-radio>
          <el-radio :label="2">
            按日选择
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-show="form.dayTimeType==1"
        label="日期:"
        prop="checkDays"
      >
        <el-date-picker
          v-model="form.checkDays"
          style="width: 95%"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="small"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item
        v-show="form.dayTimeType==2"
        label="日期:"
        prop="checkDays"
      >
        <el-date-picker
          v-model="form.checkDays"
          style="width: 95%"
          type="dates"
          placeholder="选择日期"
          size="small"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <template v-if="props.setType === 'price'">
        <el-form-item
          label="销售价（元）:"
          prop="sellPrice"
        >
          <el-input-number
            v-model="form.sellPrice"
            style="width: 95%"
            :min="0.01"
            :max="9999999"
            placeholder="请输入销售价（元）"
            size="small"
            :controls="false"
            :precision="2"
          />
        </el-form-item>
        <el-form-item
          label="库存:"
          prop="stockQuantity"
        >
          <el-input-number
            v-model="form.stockQuantity"
            style="width: 95%"
            :min="1"
            :max="9999999"
            placeholder="请输入库存"
            size="small"
            :controls="false"
            :precision="0"
          />
        </el-form-item>
        <div class="red">
          注：点击确定并设置成功后，以上被您特别设置过的日期，在默认销售价、库存被修改时不受影响，不会跟着被修改。(价格与库存日历中，特别设置过的日期的库存显示红色。)
        </div>
      </template>
    </el-form>

    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onBack"
      >上一步</el-button>
      <el-button
        type="primary"
        size="small"
        @click="onSubmit"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<style scoped lang="scss">

</style>
