<script setup>
import { ref, getCurrentInstance } from 'vue';

const app = getCurrentInstance().proxy;

const props = defineProps({
  type: {
    type: String,
    default: '',
  },
  show: {
    type: Boolean,
    default: false,
    required: true,
  },
  day: {
    type: String,
    default: '',
    required: true,
  },
  goodsId: {
    type: Number,
    default: null,
    required: true,
  },
  shopId: {
    type: Number,
    default: null,
    required: true,
  },
});

const emit = defineEmits('clear', 'success');

const tableData = ref([]);

function clearDialog() {
  emit('clear');
}
async function open() {
  const res = await app.$axios.get(app.$api.goods_pick.getGoodPickSku, {
    params: {
      dateOne: props.day,
      goodsId: props.goodsId,
      shopIds: props.shopId,
    },
  });
  tableData.value = res.data.pickGoodsSkus;
  if (res.code !== 0) return;
}
function onSubmit() {
  for (let item of tableData.value) {
    if (!item.sellPrice || !item.stockQuantity) {
      app.$message.error('请完善销售价和库存');
      return;
    }
  }
  app.$confirm('是否确认提交?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(async () => {
    const res = await app.$axios.post(app.$api.goods_pick.modifyOnePriceAndStock, {
      dateOne: props.day,
      goodsId: props.goodsId,
      shopIds: [props.shopId],
      specKList: tableData.value,
    });
    if (res.code !== 0) return;
    app.$message.success('操作成功！');
    emit('clear');
    emit('success');
  }).catch(() => {
  });
}
</script>

<template>
  <el-dialog
    :title="`${props.day}规格`"
    :visible.sync="props.show"
    width="700px"
    :before-close="clearDialog"
    @open="open"
  >
    <el-table
      :data="tableData"
      border
      style="width: 100%"
    >
      <el-table-column
        prop="specValue"
        label="规格"
      />
      <el-table-column>
        <template slot="header">
          <span class="red">*</span>
          销售价（元）
          <el-tooltip
            class="item"
            effect="dark"
            content="销售价为商品正常销售时的价格，可以编辑修改，修改后该商品将重新进行审核"
            placement="top"
          >
            <el-button type="text">
              <i class="el-icon-warning-outline"></i>
            </el-button>
          </el-tooltip>
        </template>
        <template slot-scope="{row}">
          <el-input-number
            v-model="row.sellPrice"
            style="width: 95%"
            :min="0.01"
            :max="9999999"
            placeholder="请输入销售价（元）"
            size="small"
            :controls="false"
            :precision="2"
          />
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span class="red">*</span>
          库存（件）
          <el-tooltip
            class="item"
            effect="dark"
            content="该库存表示此规格在系统中实时的可售卖库存数量，可以直接编辑修改，修改保存成功，系统中的库存数量会更新为最新库存值"
            placement="top"
          >
            <el-button type="text">
              <i class="el-icon-warning-outline"></i>
            </el-button>
          </el-tooltip>
        </template>
        <template slot-scope="{row}">
          <el-input-number
            v-model="row.stockQuantity"
            style="width: 95%"
            :min="0"
            :max="9999999"
            placeholder="请输入库存"
            size="small"
            :controls="false"
            :precision="0"
          />
        </template>
      </el-table-column>
    </el-table>
    <div
      v-if="props.type !== 'show'"
      class="red margin-t10"
    >
      注：点击确定并设置成功后，以上被您特别设置过的日期，在默认销售价、库存被修改时不受影响，不会跟着被修改。(价格与库存日历中，特别设置过的日期的库存显示红色。)
    </div>
    <span
      v-if="props.type !== 'show'"
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        type="primary"
        size="small"
        @click="clearDialog"
      >取 消</el-button>
      <el-button
        type="primary"
        size="small"
        @click="onSubmit"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<style scoped lang="scss">

</style>
