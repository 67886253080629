<script setup>
import {
  ref, reactive, onMounted, getCurrentInstance,
} from 'vue';
import SkuSpecification from '@/components/common/SkuSpecification/index.vue';

const app = getCurrentInstance().proxy;

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
    required: true,
  },
  goodsId: {
    type: Number,
    default: null,
    required: true,
  },
  isAudit: {
    type: Boolean,
    default: false,
    required: true,
  },
  backendCategoryId: {
    type: Number,
    default: null,
    required: true,
  },
  // shopList: {
  //   type: Array,
  //   dafault: () => [],
  //   required: true,
  // },
});

const emit = defineEmits('clear', 'success');

const specList = ref([]);
const defaultSpecStr = ref('');

const ruleForm = reactive({
  price: undefined, num: undefined,
});
// 是否修改了规格项
const hasModifySpec = (newList, oldStr) => {
  if (props.isAudit) {
    return 1;
  }
  // 修改
  if (!oldStr) {
    return 1;
  }
  const oldList = JSON.parse(oldStr);
  // 外层id是否相同
  if (newList[0].id !== oldList[0].id || newList[1]?.id !== oldList[1]?.id) {
    return 1;
  }
  // 规格项id是否相同
  if (newList[0].specValueList.map((item) => item.id).join(',') !== oldList[0].specValueList.map((item) => item.id).join(',')) {
    return 1;
  }
  if (newList[1]?.specValueList.map((item) => item.id).join(',') !== oldList[1]?.specValueList.map((item) => item.id).join(',')) {
    return 1;
  }
  return 0;
};

const onSubmit = () => {
  if (!specList.value.length) {
    app.$message.error('请完善规格项');
    return;
  }
  // 有规格
  for (let spec of specList.value) {
    if (!spec.id) {
      app.$message.error('请完善规格项');
      return;
    }
    if (!spec.tradeSpecValueDtos || !spec.tradeSpecValueDtos.length) {
      app.$message.error('请完善规格值');
      return;
    }
    spec.specValueList = spec.tradeSpecValueDtos;
  }
  // 价格、库存
  if (!ruleForm.price || !ruleForm.num) {
    app.$message.error('销售价和库存必须大于0');
    return;
  }
  const message = props.isAudit ? '是否确认设置并提交商品审核?' : '是否确认保存?';
  app.$confirm(message, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(async () => {
    const api = props.isAudit
      ? app.$api.goods_pick.addGoodPickSku
      : app.$api.goods_pick.updateGoodPickSku;
    const params = {
      goodsId: props.goodsId,
      isDefault: 1,
      sellPrice: ruleForm.price,
      stockQuantity: ruleForm.num,
      specParams: specList.value,
      hasModifySpec: hasModifySpec(specList.value, defaultSpecStr.value), // 受否修改了规格、规格项
      // shopIds: props.shopList.map((shop) => shop.id),
    };
    const res = await app.$axios.post(api, params);
    if (res.code !== 0) return;
    app.$message.success('操作成功！');
    emit('clear');
    emit('success');
  }).catch(() => {
  });
};
const clearDialog = () => {
  emit('clear');
};
const getSpecList = async () => {
  const res = await app.$axios.post(app.$api.goods_spec.page, {
    specName: '',
    backendCategoryId: props.backendCategoryId,
  });
  if (res.code !== 0) return;
  specList.value = [
    {
      ...res.data.records[0],
      tradeSpecValueDtos: [],
    },
  ];
};
const getDefaultSpec = async () => {
  const res = await app.$axios.get(app.$api.goods_pick.getGoodPickSku, {
    params: {
      goodsId: props.goodsId,
    },
  });
  // if (res.code !== 0) return;
  if (!props.isAudit) {
    specList.value = res.data.specs.map((item) => {
      return {
        ...item,
        tradeSpecValueDtos: item.specValueList,
      };
    });
    defaultSpecStr.value = JSON.stringify(res.data.specs);
    // 价格库存回填
    ruleForm.price = res.data.sellPrice;
    ruleForm.num = res.data.stockQuantity;
  } else {
    // 新建回填第一项
    getSpecList();
  }
};
const open = () => {
  getDefaultSpec();
};
</script>

<template>
  <el-dialog
    title="默认设置"
    :visible.sync="props.show"
    width="700px"
    :before-close="clearDialog"
    :show-close="!props.isAudit"
    :close-on-click-modal="!props.isAudit"
    :close-on-press-escape="true"
    @open="open"
  >
    <div>
      <div class="fw700 f20 margin-b10">
        每日规格
      </div>
      <el-card>
        <SkuSpecification
          :goods-id="+props.goodsId"
          :spec-list="specList"
        />
      </el-card>
      <div class="red margin-t10 annotation">
        注：每日规格被修改，各门店每日规格将同步被修改，包括您特别设置过的日期规格，且每日状态将重置为启售，请谨慎操作。(价格与库存日历中，特别设置过的日期的库存显示红色。)
      </div>

      <el-divider />

      <div class="fw700 f20 margin-b10">
        默认价格与库存
      </div>
      <el-card>
        <el-form
          ref="formRef"
          :model="ruleForm"
          label-width="140px"
        >
          <el-form-item
            label="默认销售价(元)"
            :rules="[
              {required: true, message: '请输入默认销售价', trigger: 'blur'}
            ]"
          >
            <el-input-number
              v-model="ruleForm.price"
              class="input-w300"
              size="small"
              :controls="false"
              :precision="2"
              :min="0"
              placeholder="请输入销售价"
            />
          </el-form-item>
          <el-form-item
            label="默认库存"
            :rules="[
              {required: true, message: '请输入默认库存', trigger: 'blur'}
            ]"
          >
            <el-input-number
              v-model="ruleForm.num"
              class="input-w300"
              size="small"
              :controls="false"
              :precision="0"
              :min="0"
              placeholder="请输入库存"
            />
          </el-form-item>
        </el-form>
      </el-card>
      <div class="red margin-t10 annotation">
        注：默认销售价、库存被修改，各门店日期规格使用的默认销售价、库存将同步被修改。您特别设置过日期的销售价、库存，将不受影响。(价格与库存日历中，特别设置过的日期的库存显示红色。)
      </div>
      <!-- <div>{{ props.specList }}</div> -->
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        type="primary"
        size="small"
        @click="onSubmit"
      >{{ props.isAudit ? '提交审核' : '保 存' }}
      </el-button>
    </span>
  </el-dialog>
</template>

<style scoped lang="scss">
.annotation {
  line-height: 18px;
}
</style>
