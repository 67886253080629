var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _setup.formatTitle(),
        visible: _vm.show,
        width: "550px",
        "before-close": _setup.clearDialog,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _setup.form,
            rules: _setup.rules,
            "label-position": "right",
            "label-width": "110px",
          },
        },
        [
          _setup.props.setType === "state"
            ? _c(
                "el-form-item",
                { attrs: { label: "状态设置:", prop: "homeState" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _setup.form.homeState,
                        callback: function ($$v) {
                          _vm.$set(_setup.form, "homeState", $$v)
                        },
                        expression: "form.homeState",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v(" 停售 "),
                      ]),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v(" 启售 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "选择方式:", prop: "dayTimeType" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _setup.form.dayTimeType,
                    callback: function ($$v) {
                      _vm.$set(_setup.form, "dayTimeType", $$v)
                    },
                    expression: "form.dayTimeType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(" 按起止日期 "),
                  ]),
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v(" 按日选择 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _setup.form.dayTimeType == 1,
                  expression: "form.dayTimeType==1",
                },
              ],
              attrs: { label: "日期:", prop: "checkDays" },
            },
            [
              _c("el-date-picker", {
                staticStyle: { width: "95%" },
                attrs: {
                  type: "daterange",
                  align: "right",
                  "unlink-panels": "",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  size: "small",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _setup.form.checkDays,
                  callback: function ($$v) {
                    _vm.$set(_setup.form, "checkDays", $$v)
                  },
                  expression: "form.checkDays",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _setup.form.dayTimeType == 2,
                  expression: "form.dayTimeType==2",
                },
              ],
              attrs: { label: "日期:", prop: "checkDays" },
            },
            [
              _c("el-date-picker", {
                staticStyle: { width: "95%" },
                attrs: {
                  type: "dates",
                  placeholder: "选择日期",
                  size: "small",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _setup.form.checkDays,
                  callback: function ($$v) {
                    _vm.$set(_setup.form, "checkDays", $$v)
                  },
                  expression: "form.checkDays",
                },
              }),
            ],
            1
          ),
          _setup.props.setType === "price"
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: "销售价（元）:", prop: "sellPrice" } },
                  [
                    _c("el-input-number", {
                      staticStyle: { width: "95%" },
                      attrs: {
                        min: 0.01,
                        max: 9999999,
                        placeholder: "请输入销售价（元）",
                        size: "small",
                        controls: false,
                        precision: 2,
                      },
                      model: {
                        value: _setup.form.sellPrice,
                        callback: function ($$v) {
                          _vm.$set(_setup.form, "sellPrice", $$v)
                        },
                        expression: "form.sellPrice",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "库存:", prop: "stockQuantity" } },
                  [
                    _c("el-input-number", {
                      staticStyle: { width: "95%" },
                      attrs: {
                        min: 1,
                        max: 9999999,
                        placeholder: "请输入库存",
                        size: "small",
                        controls: false,
                        precision: 0,
                      },
                      model: {
                        value: _setup.form.stockQuantity,
                        callback: function ($$v) {
                          _vm.$set(_setup.form, "stockQuantity", $$v)
                        },
                        expression: "form.stockQuantity",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "red" }, [
                  _vm._v(
                    " 注：点击确定并设置成功后，以上被您特别设置过的日期，在默认销售价、库存被修改时不受影响，不会跟着被修改。(价格与库存日历中，特别设置过的日期的库存显示红色。) "
                  ),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _setup.onBack } },
            [_vm._v("上一步")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _setup.onSubmit },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }