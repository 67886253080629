var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _setup.props.type !== "show"
        ? _c(
            "div",
            { staticClass: "margin-b10" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      _setup.showSpecDialog = true
                    },
                  },
                },
                [_vm._v(" 默认设置 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _setup.onBathSetting("price")
                    },
                  },
                },
                [_vm._v(" 批量改价格与库存 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _setup.onBathSetting("state")
                    },
                  },
                },
                [_vm._v(" 批量启售/停售 ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "shop_content" },
        [
          _vm._v(" 切换门店： "),
          _c(
            "el-select",
            {
              attrs: { size: "small" },
              on: { change: _setup.changeShop },
              model: {
                value: _setup.activeShopId,
                callback: function ($$v) {
                  _setup.activeShopId = $$v
                },
                expression: "activeShopId",
              },
            },
            _vm._l(_setup.shopList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(_setup.DayCalendar, {
        on: { click: _setup.setOneDay },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ day }) {
              return [
                _c("div", { staticClass: "margin-t5 margin-b5" }, [
                  _vm._v(" 库存： "),
                  _setup.pickCalendarsObj[day]
                    ? _c("span", { staticClass: "red" }, [
                        _vm._v(
                          " " +
                            _vm._s(_setup.pickCalendarsObj[day].stockQuantity)
                        ),
                      ])
                    : _c("span", [
                        _vm._v(
                          _vm._s(_setup.defaultPickCalendars.stockQuantity)
                        ),
                      ]),
                ]),
                _c("div", { staticClass: "margin-b5" }, [
                  _vm._v(" 状态： "),
                  _setup.pickCalendarsObj[day]
                    ? _c(
                        "span",
                        {
                          class: {
                            red: +_setup.pickCalendarsObj[day].status !== 1,
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                +_setup.pickCalendarsObj[day].status === 1
                                  ? "启售"
                                  : "停售"
                              )
                          ),
                        ]
                      )
                    : _c("span", [
                        _vm._v(
                          _vm._s(
                            +_setup.defaultPickCalendars.status === 1
                              ? "启售"
                              : "停售"
                          )
                        ),
                      ]),
                ]),
                _setup.props.type !== "show"
                  ? [
                      (_setup.pickCalendarsObj[day] &&
                        +_setup.pickCalendarsObj[day].status === 1) ||
                      (!_setup.pickCalendarsObj[day] &&
                        +_setup.defaultPickCalendars.status === 1)
                        ? _c(
                            "el-button",
                            {
                              attrs: { plain: "", size: "mini" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _setup.setOneState("stop", day)
                                },
                              },
                            },
                            [_vm._v(" 停售 ")]
                          )
                        : _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                plain: "",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _setup.setOneState("start", day)
                                },
                              },
                            },
                            [_vm._v(" 启售 ")]
                          ),
                    ]
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(_setup.SpecDialog, {
        attrs: {
          "is-audit": _setup.isAudit,
          show: _setup.showSpecDialog,
          "goods-id": +_setup.query.goodsId,
          "shop-list": _setup.shopList,
          "backend-category-id": +_setup.query.backendCategoryId,
        },
        on: {
          clear: function ($event) {
            _setup.showSpecDialog = false
          },
          success: _setup.onSpecSuccess,
        },
      }),
      _c(_setup.OfflineStoreDialog, {
        attrs: {
          "is-single": false,
          show: _setup.showOfflineStore,
          "goods-id": +_setup.query.goodsId,
          list: _setup.selectOfflineStore,
          "button-text": "下一步",
        },
        on: {
          clear: function ($event) {
            _setup.showOfflineStore = false
          },
          change: _setup.onChangeOfflineStore,
        },
      }),
      _c(_setup.BatchSettingDialog, {
        attrs: {
          show: _setup.showBathSetting,
          "set-type": _setup.bathSettingType,
          "goods-id": +_setup.query.goodsId,
          "shop-list": _setup.bathSetShopList,
        },
        on: {
          clear: function ($event) {
            _setup.showBathSetting = false
          },
          success: function ($event) {
            return _setup.getDefaultSpec()
          },
          back: _setup.onBack,
        },
      }),
      _c(_setup.DaySkuDialog, {
        attrs: {
          show: _setup.showDaySku,
          day: _setup.selectDay,
          "goods-id": +_setup.query.goodsId,
          "shop-id": +_setup.activeShopId,
          type: _setup.props.type,
        },
        on: {
          clear: function ($event) {
            _setup.showDaySku = false
          },
          success: function ($event) {
            return _setup.getDefaultSpec()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }