var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {},
    [
      _vm._l(_setup.skuData, function (item, i) {
        return _c(
          "div",
          { key: i },
          [
            _c(
              "div",
              { staticClass: "sku-header" },
              [
                _c("span", { staticClass: "margin-r20" }, [
                  _vm._v("规格 " + _vm._s(i + 1) + " :"),
                ]),
                !item.name
                  ? _c("span", { staticClass: "red" }, [_vm._v("请选择规格项")])
                  : _vm._e(),
                _c("span", { staticClass: "margin-r20 fw700" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
                _c(
                  "el-button",
                  {
                    staticClass: "margin-r20",
                    attrs: { type: "primary", size: "mini" },
                    on: {
                      click: function ($event) {
                        return _setup.showAddSkuGroup(i)
                      },
                    },
                  },
                  [_vm._v(" 选择规格项 ")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "margin-r20",
                    attrs: {
                      type: "danger",
                      size: "mini",
                      disabled: _setup.skuData.length <= 1,
                    },
                    on: {
                      click: function ($event) {
                        return _setup.deleteSku(i, item)
                      },
                    },
                  },
                  [_vm._v(" 删除规格 ")]
                ),
              ],
              1
            ),
            item.id
              ? _c(
                  "div",
                  { staticClass: "sku-content" },
                  [
                    _c("span", { staticClass: "f14" }, [_vm._v("规格值：")]),
                    !item.tradeSpecValueDtos
                      ? _c("span", { staticClass: "red f14" }, [
                          _vm._v("请设置规格值"),
                        ])
                      : _vm._e(),
                    _vm._l(item.tradeSpecValueDtos, function (value, index) {
                      return _c(
                        "el-tag",
                        {
                          key: index,
                          staticClass: "margin-r10",
                          attrs: { size: "medium", closable: "" },
                          on: {
                            close: function ($event) {
                              return _setup.onCloseVal(item, index, i)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(value.name) + " ")]
                      )
                    }),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "margin-l10",
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _setup.addSkuVal(i, item)
                              },
                            },
                          },
                          [_vm._v(" 设置规格值 ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _c("el-divider"),
          ],
          1
        )
      }),
      _c(
        "el-button",
        {
          staticStyle: { width: "100%" },
          attrs: {
            disabled: _setup.skuData.length >= 2,
            size: "small",
            type: "primary",
            plain: "",
          },
          on: { click: _setup.addSku },
        },
        [_vm._v(" +新增规格 ")]
      ),
      _setup.skuData[_setup.setSkuGroupIndex]
        ? _c(_setup.AddSkuGroup, {
            attrs: {
              "dialog-visible": _setup.showSkuGroup,
              "spec-data": _setup.skuData[_setup.setSkuGroupIndex],
              "sku-data": _setup.skuData,
            },
            on: {
              "update:dialogVisible": function ($event) {
                _setup.showSkuGroup = $event
              },
              "update:dialog-visible": function ($event) {
                _setup.showSkuGroup = $event
              },
              "update:specData": function ($event) {
                return _vm.$set(_setup.skuData, _setup.setSkuGroupIndex, $event)
              },
              "update:spec-data": function ($event) {
                return _vm.$set(_setup.skuData, _setup.setSkuGroupIndex, $event)
              },
            },
          })
        : _vm._e(),
      _setup.skuData[_setup.setSkuGroupIndex]
        ? _c(_setup.AddSkuValue, {
            ref: "skuValRef",
            attrs: {
              id: _setup.setSkuGroupId,
              "dialog-visible": _setup.showSkuValue,
              value: _setup.skuData[_setup.setSkuGroupIndex].tradeSpecValueDtos,
            },
            on: {
              "update:dialogVisible": function ($event) {
                _setup.showSkuValue = $event
              },
              "update:dialog-visible": function ($event) {
                _setup.showSkuValue = $event
              },
              "update:value": function ($event) {
                return _vm.$set(
                  _setup.skuData[_setup.setSkuGroupIndex],
                  "tradeSpecValueDtos",
                  $event
                )
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }